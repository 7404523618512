 html {
     scroll-behavior: smooth;
 }
 
body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #2E3440
}

/* .background{
    background-color: #FFEEDD;
} */